@font-face {
  font-family: 'TWKLausanne-450';
  src: url('~@clients/shared/src/assets/fonts/TWKLausanne-450.woff2') format('woff2');
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWKLausanne-550';
  src: url('~@clients/shared/src/assets/fonts/TWKLausanne-550.woff2') format('woff2');
  font-weight: 550;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-family: 'TWKLausanne-550' !important;
  font-weight: normal !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
